import React from 'react';
import { Link } from 'react-router-dom';


class About extends React.Component{
render() {
    return(
        <div className='container text-start mt-5'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">About</li>
                </ol>
            </nav>
            <p className='py-auto mt-4'> Hey, good to see you here! </p>
            {/* <ul>
                <ol>
                    Doing something!
                </ol>
            </ul> */}
            <p>TL;DR of my story:</p>
            <ul>
                <ol>
                    <li>Wanted to get into IIT.</li>
                        <li>Realized don’t want to...(thank god!)</li>
         <li>Worked at a pharmacy at 13, to learn to sell.</li>           
<li>Learnt complex medicine names, not sales. Back to the Internet.</li>
                                        <li>Wanted to get into Stanford. Got Interview. Didn't work out.</li>

<li>Learnt programming. Earned some $</li>
<li>Discovered Twitter. Met the most amazing people!</li>
<li>Moved out at 17. Lived my best life, yet.</li>
<li>Along the ride, joined <a target='_blank' href='upraised.co'>Upraised</a>, <a target='_blank' href='market.xyz'>Market.xyz</a>, <a target='_blank' href='Emerge.io'>Emerge.io</a> and <a target='_blank' href='FindSignal.studio'>FindSignal</a>. Met some of the  most amazing people! </li>
<li>Got into writing and cinema. Writing a screenplay.</li>
<li>With that, building a business and contemplating my path to greatness.</li>                    
                </ol>
            </ul>
        <br/>
            
            <p>Longer version WIP. DM me on <a target='_blank' href='twitter.com/neervj'>Twitter</a> to publish it :p</p>
            <p>P.S. I love good <a target='_blank' href='https://open.spotify.com/user/8fefzplg3rfjk9puyklx9b029'>music</a>. Send reccs!</p>  
            
        </div>
    )
}
}

export default About;
