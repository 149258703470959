import React from 'react';
import { Link } from 'react-router-dom';


class Taste extends React.Component{ 
    
    render(){
    return(
        <div className='container mx-auto text-start mt-5'>
                        <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                    <li className="breadcrumb-item" aria-current="page"><Link to='/essays'>Essays</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Thinking Mold</li>
                </ol>
            </nav>
            <div className='content-div col-lg-6 col-xl-6'>
            
                <h1 tabindex="0" data-bs-toggle="popover" data-bs-placement="right" data-bs-trigger="hover focus" data-bs-content="25 May 2023" className='heading'>Taste</h1>
            
          
            <p><br />
            People who ask "Which is your favorite movie?" have no taste.
            <br /><br/>
Aches my stomach when they are not asking it for the sake of simplicity of conversation but actually.
<br /><br/>
Put anything other than a movie there. If you love it, you cannot mention a favorite. If you have consumed enough then as well. Because you know there is so much great art that you can never have favorites.
<br /><br/>
I hate favorites. I generally hate things that bind you to a limited identity and having favorites is one of them. You get entitled to your favorites. It is the opposite of taste.
<br /><br/>
Taste is a sign of living a life. Not a long one, but a big, fulfilling, and great one.
<br /><br/>
It comes after listening to both good and bad music. After seeing places you never thought existed. Reading things that make you envious you didn't write. Having food that makes you long for it again and also having food that tastes worse than sand. Most importantly, by studying and surrounding yourself with people who have a taste. You'll find those people appreciating beauty somewhere.
<br /><br/>
Great work is created by people with taste. They are people of conviction. They like something or they hate it. Your opinion of something does not matter to them. They know their shit.
<br /><br/>
Because as humans, taste is pretty much constant. No matter how unique one thinks they are with their unique taste, if you zoom out enough, they look the same. Because all great work tastes the same. All eyes appreciate beauty.
<br /><br/>
People with a desire to do great work constantly struggle with taste. As they are striving to reach greatness but there is a gap between the work they produce and their taste.
<br /><br/>
That constant fight of impostor and perseverance builds human beings with taste.
<br /><br/> 
<br /><br/>
</p>            
<br />
<br />
<br/>
            <p style={{color : "rgba(204, 238, 253, 0.884)"}}>May 2023</p>
        </div>
        </div>
    )
}
}
export default Taste;
