import { Link } from 'react-router-dom';
import React from 'react';


class craft extends React.Component{ 

    render(){
    return(
        <div className='container mx-auto text-start mt-5 justify-content-start align-items-start'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                    <li className="breadcrumb-item" aria-current="page"><Link to='/essays'>Essays</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Craft</li>
                </ol>
            </nav>
            <div className='content-div col-lg-6 col-xl-6'>
            <span className="d-inline-block" tabindex="0" data-bs-toggle="popover" data-bs-placement="right"data-bs-trigger="hover focus" data-bs-content="30 Apr 2024">
                    <h1 className='heading strong bold'>Craft</h1>
                </span>
                <p>
                
            <br />
                What would you say if you could only speak a few sentences in your life?

                <br />
            <br />

                  It’s one of those questions that pops in your chattering mind after you look at a wall long enough. But it was worth entertaining. So, as I have been typing away my life so far, I punched on the keyboard ‘docs dot new’.

<br />
            <br />
There are few deep desires in everyone: to belong, be heard (same as being loved), and to say. Last one is most important, if you care about being great.

<br />
            <br />
I believe, you only get a few chances to say something to the world. If you are lucky, also to the next generations. Today, there are very few paths to do so. If you are an entrepreneur, you will get to say your part by building a great company. As a filmmaker, you will make a handful of films over your lifetime that will say what you want to say. Authors are in the same bucket. The last path would be through politics, which is better left unsaid. So, it seems then, the only way to say what you want to say is through your craft.

<br />
            <br />
Also, one can term what they want to say as impact. But it’s hard to agree upon how any quantifiable measure of impact is across centuries.

<br />
            <br />
Initially, every rep towards your craft is about figuring out what you want to say. Then, working on your craft long enough will enable you to maximize your chance to say and find something that is worth saying.

<br />
            <br />
Craft and Japan are often said in one verse. They are well known for caring about their craft and treating it with utmost sincerity and respect. They even have a term for someone who masters their craft: Takumi. It’s for people who devote 60,000 hours to a craft. That is 6 hours every single day for more than 27 years!

<br />
            <br />
With social media, everyone is saying what they want to say all the time. It does not allow one space to go deep into their desire to say. I am sure if Dostoyevsky was alive today, the best sentence ever written in my humble opinion i.e. “Your worst sin is that you betrayed yourself for nothing.” would have been a tweet from his anon account with 77 likes, 3 retweets, and 1 comment “P U S S Y I N B I O”.

<br />
            <br />
On top of that, if you are on these platforms, it’s easy to feel like a celebrity. I can bet you know someone who behaves like one. All it takes is a few viral tweets or videos. I mean, they have all the right to do so. Thousands of eyeballs is a fair qualification to be a celebrity I'd say. But how many actual celebrities do you know who care about their craft?

<br />
            <br />
Fame and money are the easiest, and most popular, ways to corrupt what you want to say to the world. Rather allow them to enable your craft, not disrupt it.


<br />
            <br />
            </p>
            <br />
            <br />
            <br />
            <p style={{color : "rgba(204, 238, 253, 0.884)"}}>Apr 2024</p>
        </div>
        </div>
    )
}
}

export default craft;
