// import { render } from '@testing-library/react';
import React from 'react';
import {Link} from 'react-router-dom'
// import LODASH from 'lodash'


// if (process.env.NODE_ENV !== 'production') console.log(LODASH)
// const pathname = window.location.pathname
// console.log(pathname); <img className='mb-3' height='150' src='https://imgur.com/0PrbnIP'/>




class Home extends React.Component { 
    render(){return (
        <div className=" container homepage mt-5 text-start">
            <h1 className='mb-5 mt-1'>Neeraj Chouhan</h1>
            <a href="https://imgur.com/0PrbnIP">
                <img className='mb-3' height='150' src="https://i.imgur.com/0PrbnIP.png" title="" />
            </a>
            
            <p>Hey, I'm <Link to='/about'>Neeraj</Link></p>
            <p>About work <Link to='/work'>here</Link></p>
            <p>I write sometimes <Link to='/essays'>here</Link></p>
            <p>My <a target='_blank' href='https://twitter.com/neervj'>Twitter</a> & digital <Link to='/bookshelf'>bookshelf</Link></p>
        </div>
  );
  }
}

export default Home;
