import React from 'react';
import { Link } from 'react-router-dom';


function Work() {
    return(
        <div className='container mt-5 text-start mx-auto work-list'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Work</li>
                </ol>
            </nav>
            
           <h6>Doing...</h6>
            <li>
            Running <a target='_blank' href='https://www.livetheresidency.com/'>The Residency</a> in Bangalore, India.
           </li>
            <li>
            Working on a business (generating rev) and a couple of side projects (WIP).
           </li>
            <li>
            Sometimes writing essays <Link to='/essays'>here</Link>.
           </li>
           <br/>
           <h6>Did...</h6>
            <li>
                 Wrote a screenplay.
            </li>
            <li>
                Worked on cool projects with the most amazing people at  <a target='_blank' href='https://www.findsignal.studio/'>FindSignal</a>.
            </li>
            <li>
                Closed thousands of $$$ worth of clients for an agency.
            </li>
            <li>
                Worked in Special Projects team at <a target='_blank' href='https://emerge.io'>Emerge</a>.
            </li>
           <li>
            Wrote a research paper on Augmented Reality(AR) in Education. <a  target='_blank' href='https://drive.google.com/file/d/1Dd4GnqjlP_PW39I23CVbKbgPTG4alB0i/view?usp=sharing'  style={{borderRadius:'2px'}}><img class='link-img' src="https://img.icons8.com/ios-glyphs/30/000000/external-link.png"/></a> 
           </li>
           <li>
             Product Management Intern at <a href='https://upraised.co/'>Upraised</a> (10 months)
           </li>
           <li>
           Generalist stuff at <a target='_blank' href='https://market.xyz'>Market</a>
           </li>
           <li>
            Ran a newsletter on psychology with ~300 members. You can visit the archives <Link to='/psych-archives'>here</Link> 
           </li>
           <li>
             Front-end dev at <a  target='_blank' href='https://www.revoteen.com/'>Revoteen</a> 
           </li>
           <li>
            Built several projects as a freelancer like a Chrome extension for Amazon sellers, E-commerce store to sell ppe-kits etc. etc.
           </li>           
           <br/>
           <br/><br/>
            <a target='_blank' href='https://twitter.com/neervj'>DM</a> me.
        </div>
    )
}

export default Work
