import React from 'react';
import { Link } from 'react-router-dom';


class Honor extends React.Component{ 
    
    render(){
    return (
        <div className='container mx-auto text-start mt-5'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                    <li className="breadcrumb-item" aria-current="page"><Link to='/essays'>Essays</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Honor</li>
                </ol>
            </nav>
            <div className='content-div col-lg-6 col-xl-6'>
                <span className="d-inline-block" tabindex="0" data-bs-toggle="popover" data-bs-placement="right"data-bs-trigger="hover focus" data-bs-content="5 July 2023">
                    <h1 className='heading strong bold'>Honor and Game of Thrones</h1>
                </span>
                <p>


<br />
Honor is extinct.
<br /> 
<br />
Not as much in societies where family and reputation among tribe is paramount. But the internet culture we live in — it is.
<br /> 
<br />
In French, honors (plural) means the mark of respect one had because of their position in society. Honor held high regard in all decisions and discussions concerning the tribe in the times of kings and dynasties. While today, honor is mostly occupied with personal merit or achievements a person has.
<br /> 
<br />
I think honor lies at the intersection of self-respect and truth. Some say that it is the quality of being morally right. But it cannot be manufactured. As Taleb says, anything you do for the sole purpose of raising your status will decrease it. Same with honor.
<br /> 
<br />
People have different definitions of truth and definitely different degrees of self-respect. You definitely cannot quantify honor. At the same time, cannot expect it to be the same for everyone. Usually, people play games of life with people with the same amount/kind of honor, at least they want to. But breakups, infidelity, and business betrayals happen and people realize that people they thought had the same honor as them were wrong. 
<br /> 
<br />
Since honor has left the daily father-son conversations, the more normalized lack of honor activities has become. Honorable people operate with code. As seen common in male friendships where if it’s your fight, it’s my fight. I may tell you afterward you should not have said this or that but in that moment, I’ll fight to death with you, for you.
<br /> 
<br />
Though, having honor is in no way related to winning. It is a common norm that a person without honor wins with deceit, tricks, and disgraceful strategies. Lack of honor and lack of ethics are quite correlated. An honor-less person would refer to an honorable one as foolish, stupid, and well sometimes, a leader. 
<br /> 
<br />
An interesting thing about Game of Thrones, like every great story, is that its characters are neither morally black nor white, they are grey. For example, the most honorable character, Eddard Stark (Ned) has his own unhonorable acts when you read the books.
<br /> 
<br />
The greys are quite apparent in almost every character. Even in the latest House of Dragons, our perception of every character at the beginning of the series is different from our perception at the end.
<br /> 
<br />
Even though honor is deeply tied to a person’s morality, they are not the same. In his work, George R.R. Martin shows us that it’s not that simple. Albeit humans are complex, muddled, and often completely removed from reality.
<br /> 
<br />
There is an interesting Reddit post, that mentions this interaction:
<br /> 
<br />
<strong>Robin</strong>: This march was madness. More dying every day, and for what? Some girl?
<br /> 

<strong>Morgan</strong>: Ned's girl
<br /> 

<strong>Hugo</strong>: Ned's girl
<br /> 
<br />
That’s it. This sums up everything on honor and deceit. Here are people willing to fight, willing to die for the daughter of their honorable leader, Ned. While, on the other hand, not even Tywin Lannister’s children were on his side, let alone what they did to him.
<br /> 
<br />
You can lead by fear, as Tywin did. But if you want to have people around you who are going to give you their kingdoms, armies, and most importantly love, only honor can earn you that.
<br /> 
<br />
</p>
                <br />
            <br />
            <br />
            <p style={{color : "rgba(204, 238, 253, 0.884)"}}>July 2023</p>
            </div>
        </div>
    )
}
}
export default Honor;
